/**
 * Gorgias API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { gorgiasApi } from '../helpers/gorgias'

    gorgiasApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
    async function gorgiasApi(endpoint, method, body) {
      const options = {
        method: method ? method : 'GET'
      };
    
      if (body) {
        let bodyString = body;
        if (typeof body === 'object') {
          bodyString = JSON.stringify(body);
        }
        options.body = bodyString;
      }
    
      const parseJson = async response => {
        const text = await response.text();
        try {
          const json = JSON.parse(text);
          return json;
        } catch (err) {
          return text;
        }
      };
    
      const encodedEndpoint = Buffer.from(endpoint).toString('base64');
    
      return await fetch(
        `${process.env.LAMBDA_PATH}gorgias?endpoint=${encodedEndpoint}`,
        options
      ).then(async res => ({ response: await parseJson(res), status: res.status }));
    }
    
    export { gorgiasApi };
    