// extracted by mini-css-extract-plugin
export var bold = "orders-module--bold--7db7e";
export var createAccount = "orders-module--createAccount--fd8d5";
export var dataTable = "orders-module--dataTable--60635";
export var heading = "orders-module--heading--009cf";
export var loaderContainer = "orders-module--loaderContainer--c1631";
export var open = "orders-module--open--de05b";
export var orderBillingAddress = "orders-module--orderBillingAddress--4299d";
export var orderDetail = "orders-module--orderDetail--6b481";
export var orderDetailRow = "orders-module--orderDetailRow--3e646";
export var orderDetailTable = "orders-module--orderDetailTable--bb9de";
export var orderItem = "orders-module--orderItem--0ef15";
export var paymentDetails = "orders-module--paymentDetails--59fa7";
export var productDetail = "orders-module--productDetail--60fad";
export var root = "orders-module--root--31cf1";
export var summary = "orders-module--summary--009d8";
export var summaryActions = "orders-module--summaryActions--af786";
export var summaryInner = "orders-module--summaryInner--aa4d6";
export var tableHeader = "orders-module--tableHeader--bd21d";
export var tableRow = "orders-module--tableRow--e59bf";